'use client';

import { NavMenuItem } from '@/lib/parsers/nav';
import { vars } from '@/styles';
import { mergeProps } from '@liquorice/utils';
import { Tooltip, tooltipClasses } from '@mui/material';
import { calc } from '@vanilla-extract/css-utils';
import { useRef } from 'react';
import NavList, { NavListProps } from '../NavList/NavList';
import Btn, { BtnProps } from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import { useNavBarActions } from './useNavBar';

type NavBarItemProps = FlexProps<
  'li',
  {
    item: NavMenuItem;
    ItemProps?: BtnProps;
    isRecursive?: boolean;
    NavListProps?: Omit<NavListProps, 'items'>;
  }
>;

export default function NavBarItem({
  item,
  ItemProps,
  isRecursive = false,
  NavListProps,
  ...props
}: NavBarItemProps) {
  const navTitleRef = useRef<null | HTMLButtonElement>(null);
  const { type, subItems, newWindow, external, parentId, colorSet, ...rest } = item;
  const hasSubItems = !!subItems?.length;

  const setMenu = useNavBarActions((s) => s.setMenu);

  const { href, ...passiveBtnProps } = rest;

  return (
    <Flex {...props}>
      <Flex direction="row">
        {type === 'link' ? (
          <Btn ref={navTitleRef} variant="nav" color="blue" size="small" {...rest} {...ItemProps} />
        ) : (
          <Tooltip
            placement="bottom-start"
            onOpen={() => {
              setMenu(true, colorSet);
            }}
            onClose={() => {
              setMenu(false);
            }}
            slotProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    maxWidth: 'none',
                    backgroundColor: 'transparent',
                    marginLeft: calc.multiply(vars.spacing.sm, -1),
                  },
                },
              },
            }}
            title={<NavList items={subItems} {...NavListProps} />}>
            <Btn
              {...mergeProps(
                {
                  ref: navTitleRef,
                  variant: 'nav',
                  color: 'blue',
                  size: 'small',
                  endIcon: hasSubItems ? 'chevronDown' : undefined,
                  ...passiveBtnProps,
                },
                ItemProps ?? {}
              )}
            />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
}
