'use client';

import UnstyledAccordion from '@/components/UnstyledAccordion';
import { Drawer } from '@mui/material';
import IconButton from '../IconButton';
import NavList from '../NavList';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './NavBar.css';
import { NavBarMenuProps } from './NavBar.Menu';
import { useNavBarActions } from './useNavBar';

type NavBarDrawerProps = FlexProps<
  'div',
  {
    NavBarMenuProps?: NavBarMenuProps;
  }
>;

export default function NavBarDrawer({
  NavBarMenuProps,
  colorSet: headerColorSet,
  ...props
}: NavBarDrawerProps) {
  const { primaryNav, loginNav, ctaNav } = NavBarMenuProps ?? {};
  const { drawer, setDrawer } = useNavBarActions();

  return (
    <>
      <Drawer
        open={drawer}
        anchor="right"
        elevation={0}
        onClose={() => setDrawer(false)}
        PaperProps={{
          className: styles.drawer,
        }}>
        <Flex colorSet="paper" paper cx={{ height: '100' }} {...props}>
          <Flex
            as="ul"
            direction="row"
            justifyContent="end"
            cx={{
              pT: 'xl',
              pB: 'md',
              pX: 'margin',
            }}>
            <Box>
              <IconButton icon="close" onClick={() => setDrawer(false)} size="large" />
            </Box>
          </Flex>
          <UnstyledAccordion>
            {primaryNav?.items?.map(
              ({ type, subItems, newWindow, external, parentId, ...rest }, index) => {
                return (
                  <UnstyledAccordion.Item
                    key={rest.id}
                    {...{
                      isAccordionItem: (subItems?.length ?? 0) > 0,
                      index,
                      HandleProps: { cx: { p: 'margin' } },
                      style: { border: 'none' },
                      LabelProps: {
                        children: rest.href ? (
                          <Btn variant="nav" {...rest} onClick={() => setDrawer(false)}>
                            <Txt as="h6" variant="h6" noMargin>
                              {rest.children}
                            </Txt>
                          </Btn>
                        ) : (
                          <Txt as="h6" variant="h6" noMargin>
                            {rest.children}
                          </Txt>
                        ),
                      },
                      children: (
                        <Flex cx={{ pX: 'margin' }} spacing="xs" alignItems="start">
                          <NavList direction="column" spacing="2xs" items={subItems} />
                        </Flex>
                      ),
                    }}
                  />
                );
              }
            )}
          </UnstyledAccordion>
          <UnstyledAccordion BoxProps={{ cx: { mT: 'auto' } }}>
            {loginNav?.items?.map(
              ({ type, subItems, newWindow, external, parentId, ...rest }, index) => {
                return (
                  <UnstyledAccordion.Item
                    key={rest.id}
                    {...{
                      isAccordionItem: (subItems?.length ?? 0) > 0,
                      index,
                      HandleProps: { cx: { p: 'md' } },
                      style: { border: 'none' },
                      LabelProps: {
                        children: rest.href ? (
                          <Btn variant="nav" {...rest}>
                            <Txt as="h6" variant="h6" noMargin>
                              {rest.children}
                            </Txt>
                          </Btn>
                        ) : (
                          <Txt as="h6" variant="h6" noMargin>
                            {rest.children}
                          </Txt>
                        ),
                      },
                      BodyProps: {},
                      children: (
                        <Flex cx={{ pX: 'margin' }} spacing="xs" alignItems="start">
                          <NavList direction="column" spacing="2xs" items={subItems} />
                        </Flex>
                      ),
                    }}
                  />
                );
              }
            )}
          </UnstyledAccordion>
          <Flex cx={{ p: 'margin' }}>
            {ctaNav?.items?.map(
              ({ type, subItems, newWindow, external, parentId, href, ...rest }) => {
                return href ? (
                  <Btn key={rest.id} size="large" color="blue" href={href} {...rest} />
                ) : (
                  <Btn key={rest.id} size="large" color="blue" {...rest} />
                );
              }
            )}
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
}
