'use client';

import {
  AccordionItemEntryFragment,
  ArticleEntryFragment,
  ArticleIndexEntryFragment,
  HomeEntryFragment,
  OverviewEntryFragment,
  PolicyEntryFragment,
  ProfileEntryFragment,
  ScrollItemEntryFragment,
  StandardEntryFragment,
  TabEntryFragment,
} from '@/graphql/__generated__/graphql';
import { Color } from '@/styles/partials/palette.css';
import { createContext, ReactNode, useContext } from 'react';

export type EntryFragments =
  | HomeEntryFragment
  | ArticleIndexEntryFragment
  | ArticleEntryFragment
  | StandardEntryFragment
  | OverviewEntryFragment
  | PolicyEntryFragment;

export type NonPageEntryFragment = ProfileEntryFragment;

export type MatrixEntryFragments =
  | AccordionItemEntryFragment
  | TabEntryFragment
  | ScrollItemEntryFragment;

export type AllEntryFragments = EntryFragments | MatrixEntryFragments | NonPageEntryFragment;

export type AppContext = {
  entry?: EntryFragments;
  colorSet?: Color;
};

export const appContext = createContext<AppContext | undefined>(undefined);

export const EntryProvider = ({ children, value }: { children: ReactNode; value: AppContext }) => {
  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};

export const useAppContext = (): AppContext | null => {
  const store = useContext(appContext);

  if (!store) return null;

  return store;
};

export default EntryProvider;
