import { root } from './Txt.css';
import { createUseRecipeHook, RecipeVariants } from '@/styles';
import classNames from 'classnames';

export const useTxtRecipe = createUseRecipeHook(root);
export type TxtStyleProps = RecipeVariants<typeof root>;

export type UseTxtStyleProps = TxtStyleProps & {
  className?: string;
};

export const useTxtStyle = <T extends UseTxtStyleProps>(props: T) => {
  const { className, ...rest } = useTxtRecipe(props);

  const fontClassName = ''; // isHeading ? fonts?.headings?.className : fonts?.body?.className;

  return {
    className: classNames(className, fontClassName),
    ...rest,
  };
};
