import Menu from '../Menu';
import NavBarItem from './NavBar.Item';
import { NavBarMenuProps } from './NavBar.Menu';

const NavBarCtaNav = ({ ctaNav }: Partial<NavBarMenuProps>) => {
  return (
    <Menu>
      {ctaNav?.items?.map((item) => {
        return (
          <NavBarItem
            key={item.id}
            item={item}
            ItemProps={{ variant: 'filled', scale: 'medium' }}
          />
        );
      })}
    </Menu>
  );
};

export default NavBarCtaNav;
