import { NavMenuItem } from '@/lib/parsers/nav';
import { useNavBarActions } from '../NavBar/useNavBar';
import Box from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';

export type NavListProps = FlexProps<
  'div',
  {
    items: NavMenuItem[] | undefined;
    BtnProps?: BtnProps;
  }
>;

const NavList = ({ items, BtnProps, colorSet: inheritedColorSet, ...props }: NavListProps) => {
  const setMenu = useNavBarActions((s) => s.setMenu);
  const setDrawer = useNavBarActions((s) => s.setDrawer);
  const menuTheme = useNavBarActions((s) => s.menuTheme);

  const colorSet = menuTheme ?? inheritedColorSet;

  const handleClose = () => {
    setMenu(false);
    setDrawer(false);
  };

  if (!items) return null;

  const internalLinks = items.filter(
    ({ type, href, external }) => type === 'link' && href && !external
  );
  const externalLinks = items.filter(
    ({ type, href, external }) => type === 'link' && href && external
  );

  const passive = items.filter(({ type }) => type !== 'link');

  return (
    <Flex direction="row" spacing="lg" colorSet={colorSet} {...props}>
      {passive.map(({ subItems, ...rest }) => {
        return (
          <Flex key={rest.id} spacing="2xs">
            <Txt variant="sm" color="grey" uppercase bold>
              {rest.children}
            </Txt>
            <NavList items={subItems} direction="column" BtnProps={BtnProps} />
          </Flex>
        );
      })}
      {internalLinks.length > 0 && (
        <Flex spacing="2xs">
          {internalLinks.map(({ type, subItems, newWindow, external, parentId, ...rest }) => {
            return (
              <Box key={rest.id}>
                <Btn
                  variant="nav"
                  color="black"
                  size="small"
                  justify="start"
                  onClick={handleClose}
                  {...rest}
                  {...BtnProps}
                />
              </Box>
            );
          })}
        </Flex>
      )}
      {externalLinks.length > 0 && (
        <Flex spacing="2xs">
          {externalLinks.map(({ type, subItems, newWindow, external, parentId, ...rest }) => {
            return (
              <Box key={rest.id}>
                <Btn
                  variant="filled"
                  color="white"
                  justify="start"
                  endIcon="external"
                  onClick={handleClose}
                  {...rest}
                  {...BtnProps}
                />
              </Box>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default NavList;
