import type { LogoSvgProps } from './Logo';

export const LogoSvg_Primary = (props: LogoSvgProps) => (
  <svg viewBox="0 0 223 45" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{`Kolmeo`}</title>

    <path
      d="M63.676 24.013h1.583c.08 0 .153-.038.2-.102l5.898-8.255a.245.245 0 01.2-.103h9.246c.204 0 .319.235.193.396l-9.037 11.514a.245.245 0 00-.007.292l9.11 12.95a.245.245 0 01-.201.386h-9.244a.245.245 0 01-.207-.113l-6.161-9.64a.245.245 0 00-.207-.113h-1.366a.245.245 0 00-.245.244v9.377c0 .136-.11.245-.245.245H55.52a.245.245 0 01-.245-.245V4.1c0-.135.11-.244.245-.244h7.666c.135 0 .245.11.245.244v19.668c0 .136.11.245.245.245"
      fill="#3DD993"
    />
    <path
      d="M88.076 28.309c0 3.436 2.345 5.857 5.524 5.857 3.205 0 5.55-2.42 5.55-5.857 0-3.436-2.345-5.858-5.55-5.858-3.179 0-5.524 2.422-5.524 5.858m19.413 0c0 7.732-5.89 13.46-13.889 13.46-7.974 0-13.862-5.728-13.862-13.46 0-7.732 5.888-13.459 13.862-13.459 8 0 13.889 5.727 13.889 13.459M118.478 41.091h-7.666a.245.245 0 01-.245-.245V4.1c0-.135.11-.244.245-.244h7.666c.136 0 .245.11.245.244v36.746c0 .135-.11.245-.245.245M175.086 25.68h8.662a.244.244 0 00.236-.31c-.698-2.351-2.254-3.595-4.527-3.595-2.422 0-3.936 1.268-4.608 3.596a.244.244 0 00.237.308m17.14 2.63c0 .738-.042 1.721-.123 2.316a.243.243 0 01-.243.21h-16.8c-.165 0-.28.159-.235.317.748 2.567 2.683 3.77 5.388 3.77 1.838 0 3.822-.73 5.411-2.094a.244.244 0 01.326.004l4.913 4.568c.103.096.106.26.005.357-2.652 2.545-6.464 4.011-11.072 4.011-8.026 0-13.472-5.337-13.472-13.303 0-8.044 5.342-13.615 13.107-13.615 7.583 0 12.768 5.467 12.794 13.459M203.392 28.309c0 3.436 2.345 5.857 5.524 5.857 3.205 0 5.55-2.42 5.55-5.857 0-3.436-2.345-5.858-5.55-5.858-3.18 0-5.524 2.422-5.524 5.858m19.413 0c0 7.732-5.889 13.46-13.889 13.46-7.974 0-13.862-5.728-13.862-13.46 0-7.732 5.888-13.459 13.862-13.459 8 0 13.889 5.727 13.889 13.459M128.402 21.775h.664a.244.244 0 00.24-.197c.225-1.13 1.685-6.728 8.524-6.728 3.318 0 6.127 2.413 7.552 6.755.033.1.126.17.231.17a.243.243 0 00.232-.17c1.147-3.523 3.928-6.755 7.88-6.755 5.837 0 9.537 3.593 9.537 9.476v16.52c0 .135-.11.245-.245.245h-7.666a.245.245 0 01-.245-.245V27.183c0-3.02-1.512-4.712-4.065-4.712-2.241 0-4.144 1.536-4.144 4.842v13.533c0 .135-.11.245-.244.245h-7.666a.245.245 0 01-.245-.245v-13.63c0-3.02-1.511-4.712-4.04-4.712-2.266 0-4.142 1.536-4.142 4.842v13.5c0 .135-.11.245-.245.245h-7.666a.245.245 0 01-.245-.245V15.797c0-.135.11-.244.245-.244l3.722-.002c.11 0 .208.074.237.182l1.557 5.86a.245.245 0 00.237.182"
      fill="#3DD993"
    />
    <path
      d="M41.123 22.475a13.116 13.116 0 013.861 9.306c0 7.27-5.9 13.163-13.176 13.163a13.14 13.14 0 01-9.315-3.856L3.859 22.47 22.493 3.858A13.14 13.14 0 0131.808.002c7.277 0 13.176 5.893 13.176 13.163 0 3.636-1.476 6.927-3.86 9.306v.004z"
      fill="#0082B4"
    />
    <path
      d="M26.352 31.78c0 7.269-5.9 13.163-13.176 13.163C5.899 44.943 0 39.049 0 31.779V13.163C0 5.893 5.9 0 13.176 0s13.176 5.894 13.176 13.163V31.78z"
      fill="#3DD993"
    />
    <path fill="#251EB4" d="M22.492 3.856L41.126 22.47 22.492 41.087 3.86 22.471z" />
  </svg>
);
