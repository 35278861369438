import React from 'react';
import Box, { BoxProps } from '../ui/Box';
import Flex, { FlexProps } from '../ui/Flex';

type Menu = FlexProps<'ul', { children: React.ReactNode; ItemProps?: BoxProps }>;

export default function Menu({ children, ItemProps, ...props }: Menu) {
  if (!children || !Array.isArray(children)) return null;

  return (
    <Flex
      as="ul"
      direction="row"
      alignItems="center"
      columnGap="gutter"
      cx={{ listStyle: 'none' }}
      {...props}>
      {children.map((v, i) => (
        <Box as="li" key={i} {...ItemProps}>
          {v}
        </Box>
      ))}
    </Flex>
  );
}
