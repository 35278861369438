import { IconName } from '../Icon';
import IconButton, { IconButtonProps } from '../IconButton';

export type UnstyledAccordionItemCaretProps = {
  isActive?: boolean;
  isAccordionItem?: boolean;
  openIcon?: IconName;
  OpenIconProps?: Omit<IconButtonProps, 'icon'>;
  closeIcon?: IconName;
  CloseIconProps?: Omit<IconButtonProps, 'icon'>;
};

const UnstyledAccordionItemCaret = ({
  openIcon = 'chevronUp',
  OpenIconProps,
  closeIcon = 'chevronDown',
  CloseIconProps,
  isAccordionItem = true,
  isActive,
}: UnstyledAccordionItemCaretProps) => {
  return (
    isAccordionItem && (
      <IconButton
        size="large"
        icon={isActive ? closeIcon : openIcon}
        {...(isActive ? CloseIconProps : OpenIconProps)}
      />
    )
  );
};

export default UnstyledAccordionItemCaret;
