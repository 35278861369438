'use client';

import { mergePropsClassName } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import Shim, { ShimProps } from '../Shim';
import * as styles from './NavBar.css';
import { useNavBarActions } from './useNavBar';

export default function NavBarShim(props: ShimProps) {
  const { height, fallbackHeight } = useNavBarActions();

  return (
    <Shim
      relative={false}
      {...mergePropsClassName(props, styles.shim)}
      style={assignInlineVars({ [styles.navbarHeight]: `${height ?? fallbackHeight}px` })}
    />
  );
}
