import Box, { BoxComponent, BoxProps } from '@/components/ui/Box';
import { Polymorphic } from '@/components/ui/Polymorphic';
import { ContainerStyleProps, useContainerStyle } from './useContainerStyle';

import React from 'react';

type ContainerBaseProps = ContainerStyleProps;

export type ContainerProps<C extends React.ElementType = 'div', P = NoProps> = BoxProps<
  C,
  ContainerBaseProps & P
>;

const Container: BoxComponent<'div', ContainerBaseProps> = React.forwardRef(function Container<
  C extends React.ElementType
>(props: ContainerProps<C>, ref?: Polymorphic.Ref<C>) {
  const boxProps = useContainerStyle(props);

  return <Box ref={ref} {...boxProps} />;
});

export default Container;
