'use client';

import { Breakpoint, breakpoints } from '@/styles';
import React from 'react';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { keys, mapObject } from '@liquorice/allsorts-craftcms-nextjs';

/**
 * If the window width is >= the provided {@link Breakpoint}
 *
 */
export const useBreakpoints = () => {
  /** An object listing if the window width is >= each {@link Breakpoint} */
  const [values, setValues] = React.useState<Record<Breakpoint, boolean>>();

  React.useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const newValues = mapObject(breakpoints, (breakpointMin) => windowWidth >= breakpointMin);
      setValues(newValues);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return values;
};

export const useBreakpointMin = (bp: Breakpoint, initValue = true) => {
  /** An object listing if the window width is >= each {@link Breakpoint} */
  const values = useBreakpoints();
  return values ? values[bp] : initValue;
};

/** If the window width is < the provided {@link Breakpoint} */
export const useBreakpointMax = (bp: Breakpoint, initValue = false) =>
  !useBreakpointMin(bp, initValue);

export type ResponsiveValue = string | null | undefined | null;
export type ResponsiveValues<T extends ResponsiveValue> = { [P in Breakpoint]?: T };

// export const isResponsiveValues = <T extends ResponsiveValue>(x: any): x is ResponsiveValues<T> => {}

export function useResponsiveValue(maybeResponsiveValues: undefined): undefined;
export function useResponsiveValue<T extends ResponsiveValue>(
  maybeResponsiveValues: ResponsiveValues<T> | T
): T;
export function useResponsiveValue<T extends ResponsiveValue>(
  maybeResponsiveValues?: ResponsiveValues<T>
): T | undefined {
  const breakpointStates = useBreakpoints();

  if (!maybeResponsiveValues || isString(maybeResponsiveValues) || isNumber(maybeResponsiveValues))
    return maybeResponsiveValues as T;

  const minBreakpointUsed = breakpointStates
    ? keys(breakpoints)
        .reverse()
        .find((breakpoint) => {
          return breakpointStates[breakpoint] && breakpoint in maybeResponsiveValues;
        })
    : undefined;

  // console.log({ breakpointStates, minBreakpointUsed, maybeResponsiveValues });

  return minBreakpointUsed ? maybeResponsiveValues[minBreakpointUsed] : undefined;
}
