import { vars } from '@/styles';
import Menu from '../Menu';
import NavBarItem from './NavBar.Item';
import { NavBarMenuProps } from './NavBar.Menu';

const NavBarPrimaryNav = ({ primaryNav, colorSet }: Partial<NavBarMenuProps>) => {
  return (
    <Menu>
      {primaryNav?.items?.map((item) => {
        return (
          <NavBarItem
            key={item.id}
            item={item}
            isRecursive
            NavListProps={{
              colorSet,
              paper: true,
              cx: { p: 'lg' },
              style: {
                borderBottomRightRadius: vars.spacing.md,
                borderBottomLeftRadius: vars.spacing.md,
              },
            }}
          />
        );
      })}
    </Menu>
  );
};

export default NavBarPrimaryNav;
