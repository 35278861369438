'use client';

import { mergePropsClassName } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import useResizeObserver from 'use-resize-observer';
import HideOnScroll from '../HideOnScroll';
import Box, { BoxProps } from '../ui/Box';
import Container from '../ui/Container';
import * as styles from './NavBar.css';
import NavBarShadow from './NavBar.Shadow';
import NavBarShim from './NavBar.Shim';
import { useNavBarActions } from './useNavBar';

export type NavBarInnerProps = BoxProps;

export default function NavBarInner({
  children,
  colorSet: inheritedColorSet,
  ...props
}: NavBarInnerProps) {
  const { setHeight, fallbackHeight, menuTheme } = useNavBarActions();

  const colorSet = menuTheme ?? inheritedColorSet;

  const { ref, height = fallbackHeight } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      setHeight(height);
    },
  });

  return (
    <>
      <NavBarShadow />
      <NavBarShim />
      <HideOnScroll>
        <Box
          ref={ref}
          as="nav"
          colorSet={colorSet}
          paper
          style={assignInlineVars({
            [styles.navbarHeight]: `${height ?? fallbackHeight}px`,
          })}
          {...mergePropsClassName(props, styles.root)}>
          <Container>{children}</Container>
        </Box>
      </HideOnScroll>
    </>
  );
}
